import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import SEO from "./seo";

type LayoutProps = { children: React.ReactNode; className?: string };

const PageLayout = ({ children }: LayoutProps) => (
  <div className="h-screen">
    <SEO />
    <header>
      <Navbar home={false} />
    </header>
    <main>{children}</main>
    <Footer />
  </div>
);

export default PageLayout;
