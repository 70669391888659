import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";

const Navbar = (props: { home: boolean }) => {
  const [mobileMenuHidden, setMobileMenuHidden] = useState(true);

  const nonMobileNavClasses =
    "md:bg-transparent md:text-yellow-400 md:p-0 md:border-none md:rounded-none md:tracking-normal md:text-3xl";
  const navClasses = `norse-bold block py-2 pr-4 pl-3 text-black bg-yellow-400 border-2 border-yellow-700 rounded-md font-black text-xl ${nonMobileNavClasses}`;

  return (
    <nav className="bg-zinc-800 border-gray-200 px-2 sm:px-4 fixed w-full z-[1000] top-0">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        {props.home === false ? (
          <Link to="/home" className="flex items-center w-24 h-12 lg:w-36 md:h-24">
            <StaticImage
              onClick={() => setMobileMenuHidden(true)}
              src="../assets/images/asgardian-bulls-logo.webp"
              alt="Asgardian Bulls Club Logo"
              width={150}
            />
          </Link>
        ) : (
          <AnchorLink to="/home#home" className="flex items-center w-24 h-12 lg:w-36 md:h-24">
            <StaticImage
              src="../assets/images/asgardian-bulls-logo.webp"
              alt="Asgardian Bulls Club Logo"
              onClick={() => setMobileMenuHidden(true)}
              width={150}
            />
          </AnchorLink>
        )}
        <div className="flex px-2 sm:space-x-4 flex-shrink-0 items-center sm:order-2">
          <a href="https://discord.gg/b6uYbJvfce" target="_blank" className="pr-5">
            <svg
              width="24"
              height="24"
              viewBox="0 -28.5 256 256"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid"
            >
              <g>
                <path
                  d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                  fill="rgb(250 204 21)"
                  fillRule="nonzero"
                ></path>
              </g>
            </svg>
          </a>
          <a href="https://twitter.com/AsgardianBulls" target="_blank" className="pr-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              stroke="rgb(250 204 21)"
              fill="rgb(250 204 21)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>
          <a href="https://medium.com/@asgardianbulls" target="_blank" className="pr-5">
            <svg
              width="24"
              height="24"
              viewBox="0 0 256 146"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid"
            >
              <g>
                <path
                  d="M72.2009141,1.42108547e-14 C112.076502,1.42108547e-14 144.399375,32.5485469 144.399375,72.6964154 C144.399375,112.844284 112.074049,145.390378 72.2009141,145.390378 C32.327779,145.390378 0,112.844284 0,72.6964154 C0,32.5485469 32.325326,1.42108547e-14 72.2009141,1.42108547e-14 Z M187.500628,4.25836743 C207.438422,4.25836743 223.601085,34.8960455 223.601085,72.6964154 L223.603538,72.6964154 C223.603538,110.486973 207.440875,141.134463 187.503081,141.134463 C167.565287,141.134463 151.402624,110.486973 151.402624,72.6964154 C151.402624,34.9058574 167.562834,4.25836743 187.500628,4.25836743 Z M243.303393,11.3867175 C250.314,11.3867175 256,38.835526 256,72.6964154 C256,106.547493 250.316453,134.006113 243.303393,134.006113 C236.290333,134.006113 230.609239,106.554852 230.609239,72.6964154 C230.609239,38.837979 236.292786,11.3867175 243.303393,11.3867175 Z"
                  fill="rgb(250 204 21)"
                ></path>
              </g>
            </svg>
          </a>
          <button
            data-collapse-toggle="mobile-menu-4"
            type="button"
            className="inline-flex items-center p-2 text-sm rounded-lg-lg md:hidden focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
            aria-controls="mobile-menu-4"
            aria-expanded="false"
            onClick={() => (mobileMenuHidden ? setMobileMenuHidden(false) : setMobileMenuHidden(true))}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          hidden={mobileMenuHidden}
          className="justify-between items-center w-full md:flex md:w-auto md:order-1"
          id="mobile-menu-4"
        >
          <ul
            onClick={() => (mobileMenuHidden ? setMobileMenuHidden(false) : setMobileMenuHidden(true))}
            className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-3xl md:font-medium"
          >
            <li>
              <AnchorLink to="/home#about" className={navClasses}>
                About
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/home#roadmap" className={navClasses}>
                Roadmap
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/home#art" className={navClasses}>
                Art
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/home#faq" className={navClasses}>
                FAQ
              </AnchorLink>
            </li>
            <li className="border-l-8 border-double border-amber-900"></li>
            <li>
              <Link to="/mint" className={navClasses} onClick={() => setMobileMenuHidden(true)}>
                Mint
              </Link>
            </li>
            <li>
              <Link to="/giveaway" className={navClasses} onClick={() => setMobileMenuHidden(true)}>
                Giveaway
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
