import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 bg-zinc-800 border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6">
      <span className="text-sm sm:text-center text-gray-400">
        © 2022{" "}
        <a href="https://asgardianbulls.com" className="hover:underline">
          Asgardian Bulls Club
        </a>
        . All Rights Reserved.
      </span>
    </footer>
  );
};

export default Footer;
