import React from "react";
import PageLayout from "../components/page-layout";

const NotFound = () => {
  return (
    <PageLayout>
      <p>Nope</p>
    </PageLayout>
  );
};

export default NotFound;
